import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAgent, setAgent, setAgents } from "../../redux";
import { refreshToken } from "../../api/user";
import { getAllAgents, updateAgent } from "../../api/agent";
import "react-quill/dist/quill.snow.css";
import "../setupNewAgent/CustomQuillEditor.css";
import { toast } from "react-toastify";

const EditModal = ({ setIsLoading, agentWorkflow, isOpen, onClose }) => {
  const agent = useSelector(selectAgent);
  const [selectedTab, setSelectedTab] = useState("Knowledge Base");

  const [updatedPrompt, setUpdatedPrompt] = useState(
    agent?.agent_status?.toLowerCase() === "active"
      ? agent?.agent_system_prompt
      : agent?.agent_undeploy_system_prompt
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setUpdatedPrompt(
      agent?.agent_status?.toLowerCase() === "active"
        ? agent?.agent_system_prompt
        : agent?.agent_undeploy_system_prompt
    );
  }, [agent]);

  const handleUpdateAgent = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    onClose();
    try {
      const tokenResponse = await refreshToken();
      if (tokenResponse?.status === 200) {
        localStorage.setItem(
          "userDetails",
          JSON.stringify(tokenResponse?.data)
        );
      }

      const updatedObj = {
        ...agent,
        agent_system_prompt: updatedPrompt,
      };

      const updateResponse = await updateAgent(updatedObj);
      if (updateResponse?.data) {
        dispatch(setAgent(updateResponse?.data));
        toast.success("Agent updated successfully");

        const agents = await getAllAgents();
        dispatch(setAgents(agents));
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to update agent. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50  bg-opacity-50">
      <div className="bg-gray.500 text-white rounded-lg w-3/4 max-w-[915px] border bg-gray-700">
        <div className="flex justify-between items-center px-6 py-4">
          <h2 className="text-lg font-semibold">Edit {agent?.name} Agent</h2>
          <div className="flex gap-6">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
          </div>
        </div>

        <div className="px-6 py-4">
          <div className="bg-gray.600">
            <div className="flex items-center justify-between px-6 py-4">
              <ul className="flex space-x-8 border-b border-gray-600">
                <li
                  className={`py-2 cursor-pointer ${
                    selectedTab === "Knowledge Base"
                      ? "text-[#65B6F6] border-b-2 border-[#65B6F6]"
                      : "text-gray-400"
                  }`}
                  onClick={() => setSelectedTab("Knowledge Base")}
                >
                  Knowledge Base
                </li>
                <li
                  className={`py-2 cursor-pointer ${
                    selectedTab === "Actions and Tools"
                      ? "text-[#65B6F6] border-b-2 border-[#65B6F6]"
                      : "text-gray-400"
                  }`}
                  onClick={() => setSelectedTab("Actions and Tools")}
                >
                  Actions and Tools
                </li>
                <li
                  className={`py-2 cursor-pointer ${
                    selectedTab === "Edit Prompt"
                      ? "text-[#65B6F6] border-b-2 border-[#65B6F6]"
                      : "text-gray-400"
                  }`}
                  onClick={() => setSelectedTab("Edit Prompt")}
                >
                  Edit Prompt
                </li>
                {/* <li
                  className={`py-2 cursor-pointer ${
                    selectedTab === "Workflow"
                      ? "text-[#65B6F6] border-b-2 border-[#65B6F6]"
                      : "text-gray-400"
                  }`}
                  onClick={() => setSelectedTab("Workflow")}
                >
                  Workflow
                </li> */}
              </ul>
            </div>

            <div className="px-6 pb-4">
              {selectedTab === "Knowledge Base" && (
                <>
                  <h3 className="mb-2 text-gray-400">
                    Current Knowledge Bases:
                  </h3>
                  <div className="flex justify-between">
                    {/* <div className="flex items-center p-4 bg-agent-card rounded-lg w-[250px]">
                      <input
                        type="radio"
                        name="knowledgeBase"
                        className="mr-3"
                      />
                      <div>
                        <h4 className="text-white">File Upload</h4>
                        <p className="text-gray-500">File Name here</p>
                      </div>
                    </div> */}
                    {/* <div
                      className={`flex items-center p-4 bg-agent-card rounded-lg w-[250px] cursor-pointer`}
                      onClick={() => toggleOption("File Upload")}
                    >
                      <input
                        type="radio"
                        name="tms"
                        className="mr-3"
                        checked={selectedOptions.includes("File Upload")}
                        onChange={() => toggleOption("File Upload")}
                      />
                      <div>
                        <h4 className="text-white">TMS</h4>
                        <p className="text-gray-500">File Name here</p>
                      </div>
                    </div> */}
                    <div
                      className={`flex border items-center p-4 bg-agent-card rounded-lg w-[250px] cursor-pointer`}
                    >
                      <input
                        type="radio"
                        name="fileUpload"
                        className="mr-3"
                        checked={agentWorkflow?.kb_other_docs}
                      />
                      <div>
                        <h4 className="text-white text-base">File Upload</h4>
                        <p className="text-gray-500 text-base">
                          File Name here
                        </p>
                      </div>
                    </div>
                    <div
                      className={`flex border items-center p-4 bg-agent-card rounded-lg w-[250px] cursor-pointer`}
                    >
                      <input
                        type="radio"
                        name="googleSheets"
                        className="mr-3"
                        checked={agentWorkflow?.kb_google_drive}
                      />
                      <div>
                        <h4 className="text-white text-base">Google Sheets</h4>
                        <p className="text-gray-500 text-base">
                          Sheet name here
                        </p>
                      </div>
                    </div>

                    <div className="w-[250px] flex items-center justify-center p-4 bg-agent-card rounded-lg text-white cursor-pointer text-base">
                      + Knowledge Base
                    </div>
                  </div>
                </>
              )}

              {selectedTab === "Actions and Tools" && (
                <>
                  <h3 className="mb-2 text-gray-400">
                    Current Knowledge Bases:
                  </h3>
                  <div className="flex justify-between">
                    <div
                      className={`flex border items-center p-4 bg-agent-card rounded-lg w-[250px] cursor-pointer`}
                    >
                      <input
                        type="radio"
                        name="slack"
                        className="mr-3"
                        checked={agentWorkflow?.slack_integration}
                      />
                      <div>
                        <h4 className="text-white">Slack</h4>
                        <p className="text-gray-500">Name here</p>
                      </div>
                    </div>
                    <div
                      className={`flex border items-center p-4 bg-agent-card rounded-lg w-[250px] cursor-pointer`}
                    >
                      <input
                        type="radio"
                        name="mail"
                        className="mr-3"
                        checked={agentWorkflow?.mail_integration}
                      />
                      <div>
                        <h4 className="text-white">Mail</h4>
                        <p className="text-gray-500">Name here</p>
                      </div>
                    </div>

                    <div className="w-[250px] flex items-center justify-center p-4 bg-agent-card rounded-lg text-white cursor-pointer">
                      + Action and Tools
                    </div>
                  </div>
                </>
              )}

              {selectedTab === "Edit Prompt" && (
                <div className="">
                  <textarea
                    disabled={agent?.agent_status.toLowerCase() !== "active"}
                    id="prompt"
                    value={updatedPrompt}
                    onChange={(e) => setUpdatedPrompt(e.target.value)}
                    rows={10}
                    className="rounded-md w-full text-[14px] border-none bg-gray.600  px-6 py-2 text-white  bg-gray-800 shadow-lg outline-none backdrop-blur-md overflow-y-auto custom-scrollbar"
                  />
                </div>
              )}

              {/* {selectedTab === "Workflow" && (
                <div className="text-gray-400">
                  Workflow content goes here...
                </div>
              )} */}
            </div>
          </div>
        </div>
        {/* buttons */}
        <div className="flex justify-end px-6 py-4">
          <button
            onClick={onClose}
            className="mr-4 bg-white text-gray-900 px-4 py-2 rounded-sm"
          >
            Cancel
          </button>
          <button
            onClick={(e) => handleUpdateAgent(e)}
            className="bg-[#2D76E2] px-4 py-2 rounded-sm"
          >
            Finish Editing
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
