import { createSlice } from "@reduxjs/toolkit";

export const auditLogSlice = createSlice({
  name: "logs",
  initialState: {
    selectedCallLog: null,
    callLogs: [],
  },
  reducers: {
    setCallLog: (state, action) => {
      state.selectedCallLog = action.payload;
    },
    setCallLogs: (state, action) => {
      state.callLogs = action.payload;
    },
  },
});

export const { setCallLogs, setCallLog } = auditLogSlice.actions;

export const selectCallLog = (state) => state.logs.selectedCallLog;
export const getCallLogs = (state) => state.logs.callLogs;

export default auditLogSlice.reducer;
