/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { getAgentSetupForm, getWorkflow, setAgentSetupForm } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../api/user";
import { slackToolConfig } from "../../api/agent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiCircleQuestion } from "react-icons/ci";
import {
  checkActionAndToolsConfig,
  getActionAndToolsByClientId,
  getKnowledgeBaseActionAndToolsId,
} from "../../api/action-and-tools";
export const Step5 = ({ prevStep, nextStep }) => {
  const [activeTool, setActiveTool] = useState(null);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [actionAndTools, setActionAndTools] = useState([]);
  const [actionAndToolNames, setActionAndToolNames] = useState([]);
  const [mergedObj, setMergedObj] = useState([]);
  const agentFlow = useSelector(getWorkflow);
  const [isDisable, setIsDisable] = useState(agentFlow?.slack_integration);
  const agentSetupForm = useSelector(getAgentSetupForm);
  const [selectedTools, setSelectedTools] = useState(
    agentSetupForm.selectedTools || []
  );
  const dispatch = useDispatch();

  // fetching action and tools configrations

  const fetchActionAndTools = async () => {
    try {
      const AT = await getActionAndToolsByClientId(agentSetupForm?.client_id);
      if (AT) {
        const parsedAT = AT.map((item) => ({
          ...item,
          config_json: JSON.parse(item.config_json),
        }));
        setActionAndTools(parsedAT);
        const configNamesPromises = AT.map(async ({ action_and_tool_id }) => {
          const names = await getKnowledgeBaseActionAndToolsId(
            action_and_tool_id
          );
          return names;
        });
        const configNames = await Promise.all(configNamesPromises);
        setActionAndToolNames(configNames);
        setIsNextButtonEnabled(true);
      } else {
        toast.error(AT?.data?.error_message);
      }
    } catch (error) {
      console.error("Error fetching knowledge base:", error);
      toast.error("Failed to fetch knowledge base details.");
    }
  };

  // merging the name with it's configrations

  const customMerge = () => {
    const mergedConfig = actionAndTools.map((AT) => {
      const matchedObj = actionAndToolNames.find(
        (obj) => obj.id === AT.action_and_tool_id
      );

      if (matchedObj) {
        return {
          ...AT,
          name: matchedObj.name,
        };
      }
      return AT;
    });

    setMergedObj(mergedConfig);
    setIsNextButtonEnabled(true);
  };

  //only merge when both configs and name has some value

  useEffect(() => {
    if (actionAndTools.length > 0 && actionAndToolNames.length > 0) {
      customMerge();
    }
  }, [actionAndTools, actionAndToolNames]);

  // just to run the call once

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isDisable) {
        fetchActionAndTools();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  const handleNextStep = () => {
    dispatch(
      setAgentSetupForm({
        ...agentSetupForm,
        action_and_tools_config_ids: String(mergedObj[0]?.id),
      })
    );
    nextStep();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedTools((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // TEST the connection before saving and the save the config

  const handleTestSlackConnection = async () => {
    refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err));

    const obj = {
      ...selectedTools,
      client_id: agentSetupForm?.client_id,
      action_and_tool_id: 1,
      action_and_tool_type: "Slack",
    };
    toast.warning("Testing Action And Tools Configrations.", { toastId: "4" });
    const test = await checkActionAndToolsConfig(obj);
    if (test.status === 200) {
      toast.success(test?.data?.message);
      slackToolConfig(obj)
        .then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            setIsNextButtonEnabled(true);
            fetchActionAndTools();
          } else {
            toast.error(res?.response?.data?.error_message);
          }
        })
        .catch((res) => {
          toast.error(res?.response?.data?.error_message);
        });
    } else {
      toast.error(test?.error_message);
    }
  };
  const handleNavigate = () => {
    const newTabUrl = "/slack-integration-docx";
    window.open(newTabUrl, "_blank");
  };
  return (
    <div className="w-full bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
            5
          </div>
          <p className="text-[32px] font-semibold text-white">
            Let’s connect your Actions and Tools
          </p>
        </div>

        <div className="w-full bg-[#101010] h-[1px]"></div>
        {mergedObj.length > 0 && (
          <div className="mt-6 flex flex-col gap-4">
            <h3 className="text-[20px] text-[#EEEEEE]">
              Your Actions and Tools
            </h3>
            {mergedObj.map((selectedTool, index) => (
              <div key={index} className="upload-btn-wrapper">
                <div className="btn w-[20%] rounded-md px-3 flex min-h-[5rem] justify-between items-center border border-dark-7 bg-[#222227]">
                  <div className="flex justify-start items-center overflow-x-hidden">
                    <input
                      id={`radio-${index}`}
                      type="radio"
                      name="selected-tool-radio"
                      className="w-4 h-4 text-gray-600 bg-gray-300 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor={`radio-${index}`}
                      className="ms-2 text-sm font-medium text-white dark:text-gray-400 flex flex-col justify-start items-start"
                    >
                      {selectedTool?.name?.toUpperCase()}
                      <span className="text-dark-7 ">{selectedTool?.data}</span>
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col mt-6 gap-4">
          <h3 className="text-[20px] text-[#EEEEEE]">Add Actions and Tools</h3>
          <div className="flex gap-4 flex-wrap">
            {["Slack", "Mail"].map((tool) => (
              <button
                key={tool}
                className={`bg-[#1A1B20] border-[1.81px] border-[#2D2D2D] text-white w-36 py-4 px-2 rounded ${
                  isDisable && tool === "Slack" ? "border-blue-600" : ""
                } `}
                value={tool}
                onClick={() => setActiveTool(tool)}
              >
                {tool}
              </button>
            ))}
          </div>
        </div>
        <div></div>
        {activeTool === "Slack" ? (
          <>
            {mergedObj
              ?.filter((obj) => obj?.name === "Slack")
              ?.map((obj, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <label className="text-white">{obj?.name}</label>
                    <div
                      className="text-3xl text-white  tooltip"
                      data-tip="Slack Integration Guide"
                      onClick={handleNavigate}
                    >
                      <CiCircleQuestion />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="w-full p-2 bg-[#1A1B20] text-white rounded"
                    placeholder={`Enter bot username`}
                    value={obj?.config_json?.slack_bot_user_name || ""}
                    name="slack_bot_user_name"
                    onChange={(e) => handleInputChange(e)}
                    readOnly={obj?.config_json?.slack_bot_user_name !== ""}
                  />
                  <input
                    type="text"
                    className="w-full p-2 bg-[#1A1B20] text-white rounded"
                    placeholder={`Enter channel name`}
                    value={obj?.config_json?.slack_channel_name || ""}
                    name="slack_channel_name"
                    onChange={(e) => handleInputChange(e)}
                    readOnly={obj?.config_json?.slack_channel_name !== ""}
                  />
                  <input
                    type="text"
                    className="w-full p-2 bg-[#1A1B20] text-white rounded"
                    placeholder={`Enter OAuth token`}
                    value={obj?.config_json?.slack_oauth_token || ""}
                    name="slack_oauth_token"
                    onChange={(e) => handleInputChange(e)}
                    readOnly={obj?.config_json?.slack_oauth_token !== ""}
                  />
                  <input
                    type="text"
                    className="w-full p-2 bg-[#1A1B20] text-white rounded"
                    placeholder={`Action And Tool Type`}
                    name="action_and_tool_type"
                    value={obj?.name || ""}
                    onChange={(e) => handleInputChange(e)}
                    readOnly
                  />

                  <p className="mt-2 text-blue-600 italic ">
                    The Tool is already configured
                  </p>
                </div>
              ))}

            {mergedObj?.filter((obj) => obj?.name === "Slack").length === 0 && (
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <label className="text-white mb-2 font-bold">Slack</label>
                  <div
                    className="text-3xl text-white hover:cursor-pointer"
                    onClick={handleNavigate}
                  >
                    <CiCircleQuestion />
                  </div>
                </div>
                <input
                  type="text"
                  className="w-full p-2 bg-[#1A1B20] text-white rounded"
                  placeholder={`Enter bot username`}
                  name="slack_bot_user_name"
                  onChange={(e) => handleInputChange(e)}
                />
                <input
                  type="text"
                  className="w-full p-2 bg-[#1A1B20] text-white rounded"
                  placeholder={`Enter channel name`}
                  name="slack_channel_name"
                  onChange={(e) => handleInputChange(e)}
                />
                <input
                  type="text"
                  className="w-full p-2 bg-[#1A1B20] text-white rounded"
                  placeholder={`Enter OAuth token`}
                  name="slack_oauth_token"
                  onChange={(e) => handleInputChange(e)}
                />
                <input
                  type="text"
                  className="w-full p-2 bg-[#1A1B20] text-white rounded"
                  placeholder={`Action And Tool Type`}
                  name="action_and_tool_type"
                  onChange={(e) => handleInputChange(e)}
                  value={"Slack"}
                  readOnly
                />

                {/* Test Connection Button */}
                <button
                  onClick={handleTestSlackConnection}
                  className="mt-4 bg-[#007AFF] text-white px-4 py-2 rounded"
                >
                  Test Connection
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {activeTool === "Mail" && (
              // <Step5Mail
              //   handleInputChange={handleInputChange}
              //   handleNavigate={handleNavigate}
              //   handleTestSlackConnection={handleTestSlackConnection}
              // />
              <p className="col-span-2 font-bold text-blue-500  text-center h-6">
                Coming Soon...
              </p>
            )}
          </>
        )}
      </div>
      <div className="flex justify-end gap-6 mt-6">
        <button
          onClick={prevStep}
          className=" text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
        >
          <FaArrowLeftLong size={30} />
        </button>

        <button
          onClick={handleNextStep}
          className={`w-[13%] px-4 py-2 text-white rounded-md ${
            isNextButtonEnabled
              ? "bg-[#007AFF] hover:bg-blue-600"
              : "bg-[#616161] disabled:"
          } focus:outline-none`}
          disabled={mergedObj?.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};
