import "react-quill/dist/quill.snow.css";
import "./CustomQuillEditor.css";

const DetailModal = ({ data, isOpen, onClose, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
      <div
        className="bg-black text-white rounded-lg w-[60%] max-w-4xl max-h-[50vh] overflow-y-auto border border-gray-500"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold">Escalation Details</h2>
          <div className="flex gap-6">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
          </div>
        </div>

        <div className="px-6">
            <p className="text-[18px] font-bold text-white mb-3">Message:</p>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="text-[16px] text-[#EEEEEE]">{data}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailModal;
