/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import "react-quill/dist/quill.snow.css";
import "./CustomQuillEditor.css";
import { getAgentSetupForm, setAgentSetupForm } from "../../redux";
import { useDispatch, useSelector } from "react-redux";

const Step3 = ({ prevStep, nextStep, setFormData, formData }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const agentSetupForm = useSelector(getAgentSetupForm);
  const handleNextStep = () => {
    setIsValid(true);
    nextStep();
  };
  return (
    <div className="w-full bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-4">
            <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
              3
            </div>
            <p className="text-[32px] font-semibold text-white">
              Workflow prompt
            </p>
          </div>
        </div>
        <div className="w-full bg-[#101010] h-[1px]"></div>
      </div>

      <textarea
        id="prompt"
        value={agentSetupForm?.agent_system_prompt}
        onChange={(e) =>
          dispatch(
            setAgentSetupForm({
              ...agentSetupForm,
              agent_system_prompt: e.target.value,
            })
          )
        }
        rows={15}
        className="rounded-md w-full text-[14px] border-none bg-[#101010]  px-6 py-2 text-white placeholder-white shadow-lg outline-none backdrop-blur-md overflow-y-auto custom-scrollbar"
        readOnly={!isEditable}
      />

      {!isValid && (
        <p className="text-red-500 mt-2">Pre-built prompt is required.</p>
      )}

      <div className="flex justify-between">
        <button
          onClick={() => setIsEditable(!isEditable)}
          className="bg-white hover:bg-slate-100 text-[#101010] font-semibold py-2 px-6 rounded"
        >
          {isEditable ? "Save Prompt" : "Edit Prompt"}
        </button>

        <div className="flex justify-end gap-6">
          <button
            onClick={prevStep}
            className="text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
          >
            <FaArrowLeftLong size={30} />
          </button>
          <button
            disabled={isEditable}
            onClick={handleNextStep}
            className={`${
              isEditable ? "bg-gray-400" : "bg-[#007AFF]"
            }  hover:bg-blue-600 text-white px-4 py-2 rounded-md`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3;
