import { createSlice } from "@reduxjs/toolkit";

export const escalationsLogSlice = createSlice({
  name: "escalation",
  initialState: {
    selectedEscalation: null,
    escalations: [],
  },
  reducers: {
    setEscalation: (state, action) => {
      state.selectedEscalation = action.payload;
    },
    setEscalations: (state, action) => {
      state.escalations = action.payload;
    },
  },
});

export const { setEscalations, setEscalation } = escalationsLogSlice.actions;

export const selectEscalation = (state) => state.escalation.selectedEscalation;
export const getEscalations = (state) => state.escalation.escalations;

export default escalationsLogSlice.reducer;
