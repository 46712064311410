import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/userSlice";
import { deleteUserById, getUsersByClientId } from "../api/user";
import { FaTrash } from "react-icons/fa";
import {
  getClients,
  getSelectClient,
  setAllClients,
  setSelectedClient,
} from "../redux/clientSlice";
import { toast } from "react-toastify";
import { getAllClients } from "../api/clients";

export const UserDashbord = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [users, setUsers] = useState([]);
  const allUsers = useSelector(getUsers);
  const selectedClient = useSelector(getSelectClient);
  const allClients = useSelector(getClients);
  const handleSearch = (event) => setSearchTerm(event.target.value);
  const dispatch = useDispatch();
  const handleSort = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };

  const filteredClients = users
    ?.filter((user) =>
      [user.id, user.email, user.name].some((field) =>
        field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

  const getUsersByHisClient = async (client) => {
    const parsedObj = JSON.parse(client);
    dispatch(setSelectedClient(parsedObj));
    const res = await getUsersByClientId(parsedObj?.id);
    setUsers(res?.users);
  };
  const deleteUser = async (user_id) => {
    const res = await deleteUserById(user_id);
    if (res) {
      toast.success("User Deleted Successfully");
      const clientUsers = await getUsersByClientId(selectedClient?.id);
      if (clientUsers?.users) {
        setUsers(clientUsers.users);
      } else {
        setUsers(null);
      }
    }
  };

  return (
    <div className="w-full relative pr-8 flex justify-center items-start flex-col">
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          Users
        </h2>
      </div>
      <label htmlFor="select_client" className="mb-4">
        Select Client
      </label>
      <select
        name="select_client"
        onChange={(e) => getUsersByHisClient(e.target.value)}
        className="w-full max-w-xs rounded-md p-3 bg-gray-500"
      >
        <option value="" selected disabled>
          Select client...
        </option>
        {allClients?.map((client, index) => (
          <option value={JSON.stringify(client)} key={index}>
            {client?.company_name}
          </option>
        ))}
      </select>
      <div className=" w-full relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
        <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div className="relative">
            <input
              type="text"
              id="table-search"
              className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
              placeholder="Search for Users"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("company_name")}
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("reference")}
              >
                Email
              </th>

              <th scope="col" className="px-6 py-3 cursor-pointer">
                Delete User
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredClients?.length > 0 ? (
              filteredClients?.map((user) => (
                <tr
                  key={user.id}
                  className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
                >
                  <td className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {user.id}
                  </td>
                  <td className="px-6 py-4">{user.name}</td>
                  <td className="px-6 py-4">{user.email}</td>

                  <td
                    className="px-6 py-4 text-red-600 cursor-pointer hover:text-red-400"
                    onClick={() => deleteUser(user.id)}
                  >
                    <FaTrash />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No clients found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
