import React, { useState } from "react";
import { AgentCard } from "./AgentCard";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentSetupForm,
  getAllWorkflow,
  setWorkflow,
} from "../../redux";
const Step2 = ({ nextStep, prevStep, handleChange, agentCategory }) => {
  const agentSetupForm = useSelector(getAgentSetupForm);
  const [selectedAgentType, setSelectedAgentType] = useState(
    agentSetupForm.agentType || agentCategory?.[0]?.id || 0
  );
  const [selectedCardId, setSelectedCardId] = useState(
    agentSetupForm.workFlow || null
  );
  const allWorkflows = useSelector(getAllWorkflow);
  const dispatch = useDispatch();

  const handleAgentSelect = (id) => {
    setSelectedAgentType(id);

    setSelectedCardId(null);
  };

  const handleCardSelect = (data) => {
    dispatch(setWorkflow(data));
    handleChange("workflow_id", data?.id);
    setSelectedCardId(data?.id);
    handleChange("agent_system_prompt", data?.pre_defined_system_prompt);
  };
  // Proceed to the next step
  const handleNextStep = () => {
    if (selectedCardId !== null) {
      nextStep();
    }
  };

  const filteredWorkflows = allWorkflows?.filter(
    (workflow) => workflow.workflow_category_id === selectedAgentType
  );
  return (
    <div className="w-full bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <div className="flex flex-row  items-center gap-4">
            <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
              2
            </div>
            <p className="text-[32px] font-semibold  text-white">
              Which workflow would you like to assign?
            </p>
          </div>
          <div className="pl-10">
            <span className="text-[16px] text-[#BDBDBD]">
              Don’t see what you need?{" "}
              <button className="text-blue-400">
                Consult with us to customize a workflow
              </button>
            </span>
          </div>
        </div>
        <div className="w-full bg-[#101010] h-[1px]"></div>
      </div>
      {/* Agent type tabs */}
      <div className="py-1 w-full flex justify-center items-center mb-3">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center bg-gray-600 rounded-md">
          {agentCategory?.map((agent) => (
            <li key={agent?.id} className="me-2">
              <button
                className={`py-1 px-4 rounded-md ${
                  selectedAgentType === agent?.id
                    ? "text-black bg-white border-gray-300"
                    : "text-gray-300 hover:text-black hover:bg-white hover:border-gray-300"
                }`}
                type="button"
                onClick={() => handleAgentSelect(agent?.id)}
              >
                {agent?.category_name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {/* Render cards based on selected agent type */}
      {filteredWorkflows && filteredWorkflows.length > 0 ? (
        <div className="grid grid-cols-3 gap-4 mb-8">
          {filteredWorkflows.map((data, index) => (
            <AgentCard
              key={index}
              data={data}
              isSelected={selectedCardId === data?.id}
              onSelect={() => handleCardSelect(data)}
            />
          ))}
        </div>
      ) : (
        <h1 className="text-6xl text-white">Coming Soon</h1>
      )}
      {/* Navigation buttons */}
      <div className="flex mt-4 justify-end gap-6">
        <button
          onClick={prevStep}
          className=" text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
        >
          <FaArrowLeftLong size={30} />
        </button>
        <button
          onClick={handleNextStep}
          className={`${
            selectedCardId !== null
              ? "bg-[#007AFF] hover:bg-blue-600"
              : "bg-gray-400 cursor-not-allowed"
          } text-white px-4 py-2 rounded-md w-[13%]`}
          disabled={selectedCardId === null}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default Step2;
