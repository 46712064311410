import axios from "axios";
import config from "./config";

const BASE_URL = config.BASE_URL;

export const getAllEscalations = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/admin/get-escalation-records`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
