import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientOrderData,
  getClients,
  getSelectClient,
  setOrderData,
  setSelectedClient,
} from "../redux/clientSlice";
import { getOrderData } from "../api/clients";

export const OrderData = () => {
  const [trackingId, setTrackingId] = useState("");
  const selectedClient = useSelector(getSelectClient);
  const clientOrderData = useSelector(getClientOrderData);
  const allClients = useSelector(getClients);
  const dispatch = useDispatch();
  let tableHeadings = useRef([]);
  const setlectClient = async (client) => {
    dispatch(setOrderData([]));
    const parsedObj = JSON.parse(client);
    dispatch(setSelectedClient(parsedObj));
  };
  console.log("first")
  // const deleteOrder = async (user_id) => {
  //   const res = await deleteOrderById(user_id);
  //   if (res) {
  //     toast.success("User Deleted Successfully");
  //     const clientUsers = await getUsersByClientId(selectedClient?.id);
  //     if (clientUsers?.users) {
  //       setUsers(clientUsers.users);
  //     } else {
  //       setUsers(null);
  //     }
  //   }
  // };
  const getData = async () => {
    getOrderData({
      client_id: selectedClient?.id?.toString(),
      order_id_like: trackingId,
    })
      .then((res) => {
        dispatch(setOrderData(res));
        tableHeadings.current = Object.keys(res[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    setTrackingId("");

  };
  useEffect(() => {
    tableHeadings.current = [];
    if (clientOrderData?.length > 0) {
      tableHeadings.current = Object.keys(clientOrderData[0]);
    }
  }, [clientOrderData]);
  return (
    <div className="w-full relative pr-8 flex justify-center items-start flex-col">
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          Users
        </h2>
      </div>
      <label htmlFor="select_client" className="mb-4">
        Select Client
      </label>
      <select
        name="select_client"
        onChange={(e) => setlectClient(e.target.value)}
        className="w-full max-w-xs rounded-md p-3 bg-gray-500"
      >
        <option value="" selected disabled>
          Select client...
        </option>
        {allClients?.map((client, index) => (
          <option value={JSON.stringify(client)} key={index}>
            {client?.company_name}
          </option>
        ))}
      </select>
      <div className="w-full flex justify-center items-center">
        <button className="btn btn-md border-gray-500 " onClick={getData}>
          Find Order
        </button>
      </div>
      <div className=" w-full relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
        <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div className="relative">
            <input
              type="text"
              id="table-search"
              className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
              placeholder="Last Seven Digit of Tracking ID"
              value={trackingId}
              onChange={(e) => setTrackingId(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full overflow-hidden overflow-x-scroll">
          <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
              <tr>
                {tableHeadings?.current?.map((heading, index) => (
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    key={index}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {clientOrderData?.length > 0 ? (
                clientOrderData?.map((order) => (
                  <tr
                    key={order.id}
                    className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
                  >
                    {tableHeadings?.current?.map((heading, index) => (
                      <td className="px-6 py-4" key={index}>
                        {order[heading]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No Order Record Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
