import React, { useState, useEffect, useRef } from "react";
import { getAllInvite } from "../../api/invite"; // Ensure this import path is correct
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const InviteTable = () => {
  const [invites, setInvites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  let inviteRef = useRef(true);
  const fetchInvites = async () => {
    try {
      toast.warning("Fetching All invites");
      const res = await getAllInvite();
      if (res.status === 200) {
        toast.success("Invites Fetched Successfully");
        setInvites(res.data);
      } else {
        toast.error("Error while fetching invites");
      }
    } catch (error) {
      console.error("Error fetching invites", error);
    }
  };
  useEffect(() => {
    if (inviteRef) {
      inviteRef = false;
      fetchInvites();
    }
  }, []);

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleSort = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };

  const filteredInvites = invites
    .filter((invite) =>
      [invite.id, invite.email, invite.unique_code].some((field) =>
        field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

  return (
    <div className="w-full">
      <div className="relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
        <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div className="relative">
            <input
              type="text"
              id="table-search"
              className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
              placeholder="Search for Client"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("email")}
              >
                Customer Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("expiry")}
              >
                Expiry
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("invitation_accepted")}
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("unique_code")}
              >
                Invitation Token
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredInvites.length > 0 ? (
              filteredInvites.map((invite) => (
                <tr
                  key={invite.id}
                  className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
                >
                  <td className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {invite.id}
                  </td>
                  <td className="px-6 py-4">{invite.email}</td>
                  <td className="px-6 py-4">
                    {new Date(invite.expiry).toLocaleString()}
                  </td>
                  <td
                    className={`px-6 py-4 ${
                      invite.invitation_accepted
                        ? "text-green-500"
                        : "text-yellow-500"
                    }`}
                  >
                    {invite.invitation_accepted ? "Accepted" : "Pending"}
                  </td>
                  <td className="px-6 py-4">{invite.unique_code}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No invites found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
