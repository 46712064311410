import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css";
import "../setupNewAgent/CustomQuillEditor.css";

const PurchasePhoneModal = ({
  onClose,
  formData,
  handleInputChange,
  handlePurchase,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50  bg-opacity-50">
      <div className="bg-gray.500 text-white rounded-lg w-2/4 max-w-[915px] border bg-gray-700">
        <div className="flex justify-between items-center px-6 py-4">
          <h2 className="text-lg font-semibold">Purchase Phone Number</h2>
          <div className="flex gap-6">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
          </div>
        </div>
        {/* container */}
        <div className="px-6 py-4">
          <div className="bg-gray.600">
            <form onSubmit={handlePurchase} className="w-full p-5 ">
              {/* password */}
              <div className="mb-4 w-full">
                <label htmlFor="name">Area Code</label>
                <input
                  type={"text"}
                  name="areaCode"
                  value={formData.areaCode}
                  onChange={handleInputChange}
                  placeholder="Area Code"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* buttons */}
              <div className="flex justify-end py-4">
                <button
                  onClick={onClose}
                  className="mr-4 bg-white text-gray-900 px-4 py-2 rounded-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#2D76E2] px-4 py-2 rounded-sm"
                >
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasePhoneModal;
