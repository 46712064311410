import React from "react";
import { CallAuditTable } from "../components";

export const CallAudits = () => {
  return (
    <div className="w-full">
      <CallAuditTable />
    </div>
  );
};
