import axios from "axios";
import config from "./config";

const BASE_URL = config.BASE_URL;

export const getActionAndTools = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(
      `${BASE_URL}/action-and-tools-config/by-client-id/${parse?.client_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + parse.access_token,
        },
      }
    )
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
export const checkActionAndToolsConfig = (obj) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/action-and-tools-config/check-connection`, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
export const postActionAndTools = (formData) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .put(`${BASE_URL}/action-and-tools-config/update-config`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })

    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
export const getActionAndToolsID = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/action-and-tools`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};
export const getActionAndToolsByClientId = (client_id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(
      `${BASE_URL}/action-and-tools-config/by-client-id/${client_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + parse.access_token,
        },
      }
    )
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};
export const getKnowledgeBaseActionAndToolsId = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/action-and-tools/by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};
