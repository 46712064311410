import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets";

export const SideNav = () => {
  const [active, setActive] = useState("/");
  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const navItems = [
    { name: "WORKFLOWS", path: "/", icon: "workflows-icon" },
    { name: "INVITATIONS", path: "/invitations", icon: "invitations-icon" },
    { name: "CLIENTS", path: "/clients", icon: "workflows-icon" },
    { name: "USERS", path: "/users", icon: "workflows-icon" },
    { name: "SETUP AGENT", path: "/setup-new-agent", icon: "workflows-icon" },
    { name: "AGENTS", path: "/agents", icon: "workflows-icon" },
    { name: "BACKGROUND JOBS", path: "/audit-log", icon: "workflows-icon" },
    { name: "ESCALATIONS", path: "/escalations", icon: "workflows-icon" },
    { name: "ORDER DATA", path: "/order-data", icon: "workflows-icon" },
  ];
  useEffect(() => {
    setActive(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <div className="bg-gray-800 text-gray-300">
      <aside className="fixed top-0 z-10 h-screen w-[18%] overflow-y-auto border-r border-gray-700 bg-gray-900 px-6 pb-3">
        <div className="mt-6 px-6 py-4">
          <Link to="/" title="home">
            <img src={logo} className="w-32" alt="dashboard logo" />
          </Link>
        </div>
        <ul className="mt-10 space-y-2 tracking-wide">
          {navItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                onClick={() => setActive(item.name)}
                className={`relative flex items-center space-x-4 rounded-xl px-4 py-3 ${
                  active === item.path
                    ? "bg-gradient-to-r from-sky-600 to-cyan-400 text-white"
                    : "text-gray-300 hover:bg-gray-700"
                }`}
              >
                <span className="font-medium">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
        <div className="mt-auto border-t border-gray-700 pt-4">
          <button
            onClick={logout}
            className="flex items-center space-x-4 rounded-md px-4 py-3 text-gray-300 hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <span>Logout</span>
          </button>
        </div>
      </aside>
    </div>
  );
};
