import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clients: [],
    selectedClient: {},
    clientPhoneNumber: null,
    orderData: [],
  },
  reducers: {
    setAllClients: (state, action) => {
      state.clients = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedClientPhone: (state, action) => {
      state.clientPhoneNumber = action.payload;
    },
  },
});

export const {
  setAllClients,
  setSelectedClient,
  setSelectedClientPhone,
  setOrderData,
} = clientSlice.actions;

export const getClients = (state) => state.client.clients;
export const getClientOrderData = (state) => state.client.orderData;
export const getSelectClient = (state) => state.client.selectedClient;
export const getSelectedClientPhone = (state) => state.client.clientPhoneNumber;

export default clientSlice.reducer;
