import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getClients,
  getSelectClient,
  setAllClients,
  setSelectedClient,
} from "../redux/clientSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { createUser, refreshToken } from "../api/user";
import { toast } from "react-toastify";
import { getAllClients, removeClient } from "../api/clients";
import { ConfirmModal } from "../components/agentDetail/ConfirmModal";
export const Clients = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const allClients = useSelector(getClients);
  const selectedClient = useSelector(getSelectClient);
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [showModel, setShowmodel] = useState(false);
  const [clientDeleteModel, setClientDeleteModel] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    client_id: "",
  });
  const dispatch = useDispatch();
  const handleSearch = (event) => setSearchTerm(event.target.value);

  const filteredClients = allClients?.filter((invite) =>
    [invite.id, invite.company_name, invite.unique_code].some((field) =>
      field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const selectClientAndSaveInStore = (client) => {
    setShowmodel(true);
    dispatch(setSelectedClient(client));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if password and confirmPassword match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Clear error if passwords match
    setError("");
    formData["client_id"] = selectedClient?.id;
    formData["role"] = "user";
    createUser(formData)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("User is created successfully");
          setShowmodel(false);
          setFormData({
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            client_id: "",
          });
        } else {
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmVisibility = () => {
    setIsConfirmVisible(!isConfirmVisible);
  };
  const confirmDelete = async () => {
    // refresh token
    await refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err));
    // Client id
    const clientID = selectedClientId;
    // remove client
    await removeClient(clientID)
      .then((res) => {})
      .catch((err) => console.log(err));
    try {
      const res = await getAllClients();
      if (res.status === 200) {
        dispatch(setAllClients(res?.data));
      }
    } catch (error) {
      console.error("Error fetching clients", error);
      toast.error("Error fetching clients");
    }
    // Close Modal
    setClientDeleteModel(false); // Close the modal after deletion
  };

  const cancelDelete = () => {
    setClientDeleteModel(false); // Close the modal without deleting
  };
  return (
    <div className="w-full relative pr-8">
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          Clients
        </h2>
        <Link
          className="btn bg-none px-4 py-2 border rounded-md"
          to={"/create-client"}
        >
          Create Client
        </Link>
      </div>
      <div className="relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
        <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div className="relative">
            <input
              type="text"
              id="table-search"
              className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
              placeholder="Search for Client"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 cursor-pointer">
                ID
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer">
                Company Name
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer">
                Refrence
              </th>

              <th scope="col" className="px-6 py-3 cursor-pointer text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.length > 0 ? (
              filteredClients.map((client) => (
                <tr
                  key={client.id}
                  className="border-b bg-gray-800 border-gray-700 hover:bg-gray-900"
                >
                  <td className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {client.id}
                  </td>
                  <td className="px-6 py-4">{client.company_name}</td>
                  <td className="px-6 py-4">{client.reference}</td>

                  <td className="px-6 py-4 flex gap-4 justify-center items-center text-green-600 cursor-pointer hover:text-green-400 hover:animate-pulse">
                    <p
                      className="border inline px-3 py-1 border-gray-500"
                      onClick={() => selectClientAndSaveInStore(client)}
                    >
                      Create User
                    </p>
                    <p
                      className="border inline px-3 py-1 border-gray-500 text-red-500"
                      onClick={() => {
                        setClientDeleteModel(true);
                        setSelectedClientId(client?.id);
                      }}
                    >
                      Delete Client
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No clients found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {showModel && (
          <div className="max-w-lg w-full absolute top-[20%] left-[20%] bg-[#374151] z-30 rounded-md ">
            <div
              className="text-lg flex text-end justify-end items-center pr-4 cursor-pointer hover:text-black"
              onClick={() => setShowmodel(false)}
            >
              X
            </div>
            <form onSubmit={handleSubmit} className="max-w-xl  p-5 ">
              {/* password */}
              <div className="mb-4 w-full">
                <label htmlFor="name">Name</label>
                <input
                  type={"text"}
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Name"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4 w-full">
                <label htmlFor="email">Email</label>
                <input
                  type={"email"}
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <label htmlFor="password">Password</label>
              <div className="mb-4 relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  minLength={8}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <FaEyeSlash color="#4D4D4D" size={16} />
                  ) : (
                    <FaEye color="#4D4D4D" size={16} />
                  )}
                </div>
              </div>
              {/* Confirm */}
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              <label htmlFor="confirmPassword">Confirm your password</label>
              <div className="mb-4 relative">
                <input
                  type={isConfirmVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm Password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  minLength={8}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={toggleConfirmVisibility}
                >
                  {isConfirmVisible ? (
                    <FaEyeSlash color="#4D4D4D" size={16} />
                  ) : (
                    <FaEye color="#4D4D4D" size={16} />
                  )}
                </div>
              </div>
              {/* Sign */}
              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full border border-gray-400 text-blacek px-6 py-2 rounded-md hover:text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      {clientDeleteModel && (
        <ConfirmModal
          confirmDelete={() => confirmDelete()}
          cancelDelete={() => cancelDelete()}
          text={"Client"}
        />
      )}
    </div>
  );
};
