import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    selectedUser: {},
    user_number: null,
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setUserNumber: (state, action) => {
      state.user_number = action.payload;
    },
  },
});

export const { setUsers, setSelectedUser, setUserNumber } = userSlice.actions;

export const getUsers = (state) => state.user.users;
export const getSelectedUser = (state) => state.user.selectedUser;
export const getNumber = (state) => state.user.user_number;
export default userSlice.reducer;
