import React from "react";
import { EscalationsAuditTable } from "../components";

export const Escalations = () => {
  return (
    <div className="w-full">
      <EscalationsAuditTable />
    </div>
  );
};
