import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgents, selectAgentWorkflow, setAgent, setAgents } from "../redux";
import { getAllAgents, removeAgent, updateAgentStatus } from "../api/agent";
import { toast } from "react-toastify";
import EditModal from "../components/agentDetail/EditModal";
import { ConfirmModal } from "../components/agentDetail/ConfirmModal";

import { useNavigate } from "react-router-dom";
import { refreshToken } from "../api/user";
export const Agents = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allAgents = useSelector(getAgents);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const agentWorkflow = useSelector(selectAgentWorkflow);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearch = (event) => setSearchTerm(event.target.value);
  const handleSort = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };
  const filteredClients = allAgents
    ?.filter((invite) =>
      [invite.id, invite.name, invite.email].some((field) =>
        field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

  const setActiveAndInActiveAgent = async (value) => {
    setIsLoading(true);
    const updated = await updateAgentStatus(value);
    dispatch(setAgent(updated?.data.data));
    if (updated) {
      const res = getAllAgents();
      res.then((data) => {
        dispatch(setAgents(data));
        toast.success("Agent updated successfully");
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const res = getAllAgents();
    res.then((data) => {
      dispatch(setAgents(data));
    });
  }, []);

  const confirmDelete = async () => {
    // refresh token
    await refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err));
    // Agent id
    const agentID = selectedAgentId;
    // remove agent
    await removeAgent(agentID)
      .then((res) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
    // Close Modal
    setIsDeleteModalOpen(false); // Close the modal after deletion
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false); // Close the modal without deleting
  };
  return (
    <div className="w-full relative pr-8">
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          Agents
        </h2>
      </div>
      {isLoading ? (
        <div className=" w-full flex justify-center items-center">
          <span className="loading loading-bars loading-md"></span>{" "}
        </div>
      ) : (
        <div className="relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
          <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
            <div className="relative">
              <input
                type="text"
                id="table-search"
                className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
                placeholder="Search for Agents"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer"
                  onClick={() => handleSort("name")}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer"
                  onClick={() => handleSort("id")}
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer"
                  onClick={() => handleSort("company_name")}
                >
                  Company Name
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer text-center"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredClients?.length > 0 ? (
                filteredClients?.map((agent) => (
                  <tr
                    key={agent?.id}
                    className="border-b bg-gray-800 border-gray-700 hover:bg-gray-900"
                  >
                    <td className="px-6 py-4">{agent?.name}</td>
                    <td className="px-6 py-4 font-medium whitespace-nowrap text-white">
                      {agent?.deployed_phone_number}
                    </td>
                    <td className="px-6 py-4">{agent?.company_name}</td>

                    <td className="px-6 py-4 flex justify-center items-center gap-4 cursor-pointer hover:text-green-400 ">
                      <p
                        style={{
                          color: `${
                            agent.agent_status === "active" ? "red" : "green"
                          }`,
                        }}
                        className="border inline px-3 py-1 border-gray-500 hover:animate-pulse"
                        onClick={() =>
                          setActiveAndInActiveAgent({
                            id: agent?.id,
                            agent_status:
                              agent.agent_status === "active"
                                ? "inactive"
                                : "active",
                          })
                        }
                      >
                        {agent?.agent_status === "active"
                          ? "Inactivate Agent"
                          : "Activate Agent"}
                      </p>
                      <p
                        className="border inline px-3 py-1 border-gray-500 hover:animate-pulse"
                        onClick={() => {
                          dispatch(setAgent(agent));
                          setIsModalOpen(true);
                        }}
                      >
                        Edit
                      </p>
                      <p
                        className="border inline px-3 py-1 border-gray-500 hover:animate-pulse text-red-500"
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                          setSelectedAgentId(agent?.id);
                        }}
                      >
                        Delete
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No agent found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <EditModal
        agentWorkflow={agentWorkflow}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsLoading={setIsLoading}
      />
      {isDeleteModalOpen && (
        <ConfirmModal
          confirmDelete={() => confirmDelete()}
          cancelDelete={() => cancelDelete()}
          text={"Agent"}
        />
      )}
    </div>
  );
};
