import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { CustomQuillEditor } from "./CustomQuillEditor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addWorkflows } from "../api/workFlows";
import { getAllPredefinedModel } from "../api/predefinedModel";
import { getAllWorkflowCategories } from "../api/workflowCategroies";
export const AddWorkFlow = () => {
  const navigate = useNavigate();
  const [workflowCategories, setWorkflowCategories] = useState([]);
  const [predefinedModels, setPredefinedModels] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [feature_actions, setFeature_actions] = useState("");
  const [pre_defined_system_prompt, setPredefinedPrompt] = useState("");
  const [number_of_steps_to_setup_agent, setNumberOfSteps] = useState("");
  const [monthly_cap_amount_in_seconds, setCapAmount] = useState("");
  const [human_resolution_time, setHumanResolution] = useState("");
  const [predefined_models_id, setPredefinedModel] = useState("1");
  const [workflow_category_id, setWorkflowCategory] = useState("");
  const [checks, setChecks] = useState({
    slack_integration: false,
    deployable_on_phone: false,
    deployable_on_web: false,
    mail_integration: false,
    kb_google_drive: false,
    kb_other_docs: false,
    kb_tms_ulala: false,
    kb_tms_speedx: false,
    predefined_model: false,
  });

  const handleCheckChange = (e) => {
    setChecks({ ...checks, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      title,
      description,
      feature_actions,
      pre_defined_system_prompt,
      number_of_steps_to_setup_agent,
      monthly_cap_amount_in_seconds,
      human_resolution_time,
      predefined_models_id,
      workflow_category_id,
      ...checks,
    };
    try {
      const data = await addWorkflows(formData);
      toast.warning("Adding Workflow");
      if (data.status === 200) {
        toast.success("Workflow created");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      toast.error("Error while Adding WorkFlow", error);
    }
  };
  const fetchWorkflowCategory = async () => {
    try {
      const workflow = await getAllWorkflowCategories();
      setWorkflowCategories(workflow);
      const predefinedModel = await getAllPredefinedModel();
      setPredefinedModels(predefinedModel);
    } catch (error) {
      console.error("Error fetching workflow categories:", error);
    }
  };
  useEffect(() => {
    fetchWorkflowCategory();
  }, []);
  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <form
        className="w-full space-y-4 p-8 bg-gray-800 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-white text-2xl mb-6">Submit Form</h2>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Feature Action
          </label>
          <div className="mb-4">
            <CustomQuillEditor
              setValue={setFeature_actions}
              value={feature_actions}
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            System Prompt
          </label>
          <textarea
            className="text-white bg-transparent border"
            rows={5}
            cols={100}
            onChange={(e) => setPredefinedPrompt(e.target.value)}
          ></textarea>
        </div>
        <label className="inline-flex items-center text-white">
          <input
            type="checkbox"
            name="predefined_model"
            checked={checks.predefined_model}
            onChange={handleCheckChange}
            className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
          />
          <span className="ml-2">Predefined Model</span>
        </label>
        {checks?.predefined_model && (
          <div className="mb-4 w-full flex flex-col">
            <label htmlFor="predefined_model" className="w-full">
              Predefined Model
            </label>
            <select
              name="predefined_model"
              id="predefined_model_id"
              onChange={(e) => setPredefinedModel(e.target.value)}
              className="input input-primary bg-gray-700 p-2  rounded-md"
              value={predefined_models_id}
            >
              {predefinedModels?.map((cat) => (
                <option value={cat?.id}>{cat?.model_name}</option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4 w-full flex flex-col">
          <label htmlFor="workflow_category" className="w-full">
            Work Flow Category
          </label>
          <select
            name="workflow_category"
            id="workflow_category_id"
            onChange={(e) => setWorkflowCategory(e.target.value)}
            className="input input-primary bg-gray-700 p-2  rounded-md"
            required
          >
            <option selected disabled>
              Select Category
            </option>
            {workflowCategories?.map((cat) => (
              <option value={cat?.id}>{cat?.category_name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Human Resolution Time <sup>in sec</sup>{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={human_resolution_time}
            onChange={(e) => setHumanResolution(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
          monthly_cap_amount_in_seconds <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={monthly_cap_amount_in_seconds}
            onChange={(e) => setCapAmount(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Number Of Steps <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            min={1}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={number_of_steps_to_setup_agent}
            onChange={(e) => setNumberOfSteps(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Additional Features
          </label>
          <div className="flex flex-col gap-2">
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="slack_integration"
                checked={checks.slack_integration}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Slack Integration</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="deployable_on_phone"
                checked={checks.deployable_on_phone}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Deploy on Phone</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="deployable_on_web"
                checked={checks.deployable_on_web}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Deploy on Web</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="mail_integration"
                checked={checks.mail_integration}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Email integration</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="kb_google_drive"
                checked={checks.kb_google_drive}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Google Drive</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="kb_other_docs"
                checked={checks.kb_other_docs}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">Other Docs</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="kb_tms_ulala"
                checked={checks.kb_tms_ulala}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">TMS Ulala</span>
            </label>
            <label className="inline-flex items-center text-white">
              <input
                type="checkbox"
                name="kb_tms_speedx"
                checked={checks.kb_tms_speedx}
                onChange={handleCheckChange}
                className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
              />
              <span className="ml-2">TMS Speedx</span>
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-gray-600 text-white font-semibold rounded-md hover:bg-gray-500"
        >
          Submit
        </button>
      </form>
    </div>
  );
};
